import { useSelectedGoalUID } from '@api/hooks/topology/useSelectedGoal';
import usePendingPaymentStatus from '@layout/PaymentLayout/usePendingPaymentStatus';
import { isEmpty } from 'lodash';

const usePurchaseCTAHandler = (goalUID) => {
  const selectedGoalUID = useSelectedGoalUID();

  const { pendingPaymentStatus } = usePendingPaymentStatus(
    goalUID || selectedGoalUID
  );

  const CTA = !isEmpty(pendingPaymentStatus)
    ? 'Complete payment'
    : 'Get subscription';
  return CTA;
};

export default usePurchaseCTAHandler;
