/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { useState, lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import Paper from '@base/Paper/Paper';
import H4 from '@base/H4/H4';
import P1 from '@base/P1/P1';
import Button from '@base/Button/Button';
import Thumbnail from '@base/Thumbnail/Thumbnail';
import P2 from '@base/P2/P2';
import Link from '@base/Link/Link';
import Share from '@icon/Share';
import AddToList from '@icon/AddToList';
import AddedToList from '@icon/AddedToList';
import Play from '@icon/Play';
import {
  isCurrentlyLive,
  isUpcoming as itemIsUpcoming,
  isRecorded as itemIsRecorded
} from '@models/Item';
import {
  dateWithTime,
  getDateShort,
  getDateMonthString,
  formatTime
} from '@utils/dates';
import boxShadowGenerator from '@utils/boxShadowGenerator';
import pushRoute from '@utils/pushRoute';
import {
  programmeTypes,
  itemTypes,
  backupCoverPhoto,
  sessionStates
} from '@constants/index';
import { Spacings } from '@styles/index';
import Dialog from '@comp/Dialog/Dialog';
import usePurchaseCTAHandler from '@hooks/usePurchaseCTAHandler';
import { getIsSubscribedToGoal } from '@selectors/subscription';

const ShareDialog = lazy(() =>
  import('../../Composite/ShareDialog/ShareDialog')
);

const LessonBannerWrapper = styled.div`
  height: 265px;
  width: ${({ $itemWidth }) => $itemWidth || '848px'};
`;

const Wrapper = styled(Paper)`
  display: flex;
  position: relative;
  overflow: hidden;
  padding: ${Spacings.SPACING_8B} ${Spacings.SPACING_12B};
  height: 100%;
  justify-content: space-between;
  align-items: center;
`;

const SubTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${Spacings.SPACING_1B};
  margin-bottom: ${Spacings.SPACING_8B};
`;

const ShadowPulse = keyframes`
  0% { box-shadow: ${boxShadowGenerator({
    yOffset: Spacings.SPACING_0B,
    blur: Spacings.SPACING_0B,
    color: 'rgba(235,87,87, 0.4)'
  })}; }
  70% { box-shadow: ${boxShadowGenerator({
    yOffset: Spacings.SPACING_0B,
    blur: Spacings.SPACING_0B,
    spread: '10px',
    color: 'rgba(235,87,87, 0)'
  })}; }
  100% {
    box-shadow: none;
  }
`;

const Live = styled.div`
  margin-left: ${Spacings.SPACING_2B};
  margin-right: ${Spacings.SPACING_3B};
  height: 8px;
  width: 8px;
  background-color: var(--color-i-red);
  border-radius: 50%;
  box-shadow: ${boxShadowGenerator({
    yOffset: Spacings.SPACING_0B,
    blur: Spacings.SPACING_0B,
    color: 'rgba(235,87,87, 0.4)'
  })};
  animation: ${ShadowPulse} 1s infinite;
`;

const SubText = styled(P1)`
  color: var(--color-text-primary);
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  button:first-child {
    margin-right: ${Spacings.SPACING_4B};
  }
  z-index: 1;
`;

const CourseThumbnail = styled(Thumbnail)`
  height: 200px;
  width: 355px;
  border-radius: ${Spacings.SPACING_2B};
  margin-left: ${Spacings.SPACING_18B};
`;

const CourseTitle = styled(P2)`
  width: 320px;
  margin-top: ${Spacings.SPACING_6};
`;

const ItemTitle = styled(H4)`
  width: 320px;
  margin-top: ${({ $isSpecialClass }) =>
    $isSpecialClass ? Spacings.SPACING_4B : Spacings.SPACING_3B};
`;

const isMissed = (itemData) => {
  if (itemData.type === itemTypes.POST && !itemData.hasWatched) return true;
  if (itemData.type === itemTypes.QUIZ && !itemData.hasAttended) return true;
};

const getIsLive = ({ item, isRecorded }) => {
  if (isRecorded) return false;
  return item.type === itemTypes.POST ? isCurrentlyLive(item) : item?.isLive;
};

const LessonBanner = ({ data, enroll, itemWidth }) => {
  const isWindowDefined = typeof window !== 'undefined';

  const { sessionData, course } = data;
  const {
    thumbnail,
    courseType,
    relative_link: relativeLink,
    permalink: coursePermaLink,
    state = sessionStates.COMPLETED,
    secondsBeforeLive = 150,
    type,
    rank: sessionRank,
    has_watched: hasWatched,
    has_attended: hasAttended,
    start_time: quizStartTime
  } = sessionData;
  const {
    name: courseName,
    uid: courseUID,
    goal,
    isUpcoming: isCourseUpcoming,
    is_special: isSpecial,
    coverPhoto = backupCoverPhoto
  } = course;
  const isEnrolled =
    useSelector(({ courses }) => courses.data[courseUID]?.isEnrolled) || false;

  const isSubscribed = useSelector(getIsSubscribedToGoal(goal?.uid));

  let { live_at: startsAt, name: sessionName } = sessionData;
  if (type === itemTypes.QUIZ) {
    startsAt = quizStartTime;
  }

  const item = {
    state,
    liveAt: startsAt,
    secondsBeforeLive,
    type,
    hasWatched,
    hasAttended
  };
  const isUpcoming = itemIsUpcoming(item);
  const isRecorded = itemIsRecorded(item);

  const isLive = getIsLive({ item, isRecorded });

  const [showShareOptions, setShowShareOptions] = useState(false);
  const CTALabel = usePurchaseCTAHandler(goal?.uid);

  const onShareClick = (e) => {
    e.stopPropagation();
    setShowShareOptions(true);
  };

  const gotoCoursePage = () => {
    const courseRelativeUrl = coursePermaLink.substr(
      coursePermaLink.indexOf('.com') + 4
    );
    window.open(courseRelativeUrl, '_blank');
  };
  const enrollCourseData = { uid: courseUID, isEnrolled };
  const renderPrimaryButton = () => {
    let buttonText = 'Enroll';
    let icon = <AddToList size="20px" color="var(--color-base-1)" />;
    let handleClick = (e) => {
      e.stopPropagation();
      enroll?.(enrollCourseData)();
    };
    if (!isSubscribed && !isSpecial) {
      buttonText = CTALabel;
      icon = null;
      handleClick = (e) => {
        e.stopPropagation();
        pushRoute(
          '/goal/[goalSlug]/[goalUID]/subscribe',
          `/goal/${goal?.slug}/${goal?.uid}/subscribe`
        );
      };
    } else if (isEnrolled && !isCourseUpcoming) {
      if (type === itemTypes.POST) {
        buttonText = 'Watch now';
        icon = <Play size="16px" color="var(--color-base-1)" />;
        handleClick = (e) => {
          e.stopPropagation();
          window.open(relativeLink, '_blank');
        };
      }
      if (type === itemTypes.QUIZ) {
        buttonText = 'Begin test';
        icon = <Play size="16px" color="var(--color-base-1)" />;
        handleClick = (e) => {
          e.stopPropagation();
          window.open(relativeLink, '_blank');
        };
      }
    } else if (isEnrolled && isCourseUpcoming) {
      buttonText = 'Enrolled';
      icon = <AddedToList size="20px" color="var(--color-text-primary)" />;
      handleClick = () => {};
    }
    return (
      <Button
        grid={4}
        label={buttonText}
        onClick={handleClick}
        variant={isUpcoming && isEnrolled ? 'hollow' : 'filled'}
        color={isUpcoming && isEnrolled ? 'black' : 'green'}
        icon={icon}
      />
    );
  };

  const renderButtons = () => (
    <ButtonsWrapper>
      {renderPrimaryButton()}
      <Button
        grid={3}
        label="Share"
        onClick={onShareClick}
        color="black"
        icon={<Share size="20px" color="var(--color-text-primary)" />}
        variant="hollow"
      />
    </ButtonsWrapper>
  );

  const getSecondaryText = () => {
    let secondaryText = `Started at ${formatTime(startsAt)}`;
    if (isCourseUpcoming || isUpcoming) {
      secondaryText = `Starts on ${getDateMonthString(
        startsAt,
        true
      )}, ${formatTime(startsAt)}`;
    } else if (isLive) secondaryText = `Live  •  ${secondaryText}`;
    else if (isRecorded) {
      const sessionMissed = isMissed(item);
      secondaryText = `${
        type === itemTypes.POST ? 'Lesson' : 'Test'
      } ${sessionRank} · `;
      if (sessionMissed) secondaryText += 'Missed · ';
      secondaryText += `${getDateShort(startsAt)}`;
    } else {
      secondaryText = `${
        type === itemTypes.POST ? 'Lesson' : 'Test'
      } ${sessionRank}`;
      secondaryText += ` · Starts on ${dateWithTime(startsAt)}`;
    }
    return secondaryText;
  };

  const renderContent = () => {
    const isSpecialClass =
      ((courseType === programmeTypes.COURSE ||
        courseType === programmeTypes.ONLINE_WITH_EXTERNAL_STREAM) &&
        isSpecial) ||
      isUpcoming;
    return (
      <>
        <div color="var(--color-text-primary)">
          <Link
            href={`/${
              courseType === programmeTypes.COURSE ? 'course' : 'test-series'
            }/[slug]/[courseUID]`}
            as={relativeLink}
          >
            <CourseTitle color="var(--color-text-secondary)" lineClamp={1}>
              {courseName}
            </CourseTitle>
          </Link>

          <ItemTitle
            color="var(--color-text-primary)"
            $isSpecialClass={isSpecialClass}
            lineClamp={2}
          >
            {sessionName}
          </ItemTitle>
          <SubTextWrapper>
            {isLive ? <Live /> : null}
            <SubText>{getSecondaryText()}</SubText>
          </SubTextWrapper>
          {renderButtons()}
        </div>
        <CourseThumbnail imgUrl={thumbnail || coverPhoto || backupCoverPhoto} />
      </>
    );
  };
  const shareData = {
    permalink: `https://unacademy.com${relativeLink}`
  };
  return (
    <>
      <LessonBannerWrapper $itemWidth={itemWidth}>
        <Wrapper onClick={gotoCoursePage}>{renderContent()}</Wrapper>
        <Dialog
          onClose={() => setShowShareOptions(false)}
          dialogState={showShareOptions}
        >
          {isWindowDefined && (
            <Suspense fallback={null}>
              <ShareDialog data={shareData} useDataLink />
            </Suspense>
          )}
        </Dialog>
      </LessonBannerWrapper>
    </>
  );
};

export default LessonBanner;

LessonBanner.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  course: PropTypes.objectOf(PropTypes.any).isRequired,
  enroll: PropTypes.func,
  itemWidth: PropTypes.number
};

LessonBanner.defaultProps = {
  enroll: null,
  itemWidth: 848
};
