import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Carousel from '@comp/Carousel/Carousel';
import RenewalBanner from '@cont/Banner/RenewalBanner';
import LegendsBanner from '@cont/Banner/LegendsBanner';
import LessonBanner from '@cont/Banner/LessonBanner';
import TestSeriesBanner from '@cont/Banner/TestSeriesBanner';
import GenericBanner from '@cont/Banner/GenericBanner';
import CombatBanner from '@cont/Banner/CombatBanner';
import { bannerStates } from '@constants/index';
import useWindowSize from '@hooks/useWindowSize';
import { getPlusSubscriptionInfo } from '@api/selectors/subscription';
import useMyInfo from '@api/hooks/auth/useMyInfo';

const Renew = styled(RenewalBanner)`
  margin-top: 0px;
`;

const LegendsBannerWrapper = styled.div`
  height: 265px;
  width: ${({ $itemWidth }) => $itemWidth || '848px'};
`;

const CarouselFeedBlock = ({
  block,
  goalUID,
  enroll,
  preSubscribed = false,
  gridColumn,
  itemWidth,
  itemHeight,
  imgWidth,
  leftOverlay,
  showLabel
}) => {
  const { isMobile } = useWindowSize();
  const plusSubscription = useMyInfo(getPlusSubscriptionInfo(goalUID));

  if (!block) return null;
  const renderBanners = () => {
    const banners = [];
    block.carouselFeed.forEach((data, index) => {
      const bannerType = data.bannerDetails?.type;
      if (bannerType === bannerStates.ITEM) {
        banners.push(
          <LessonBanner
            key={String(index)}
            data={data}
            enroll={enroll}
            gridColumn={gridColumn}
            itemWidth={itemWidth}
          />
        );
      } else if (bannerType === bannerStates.LEGENDS) {
        // Legends banner
        banners.push(
          <LegendsBannerWrapper $itemWidth={itemWidth}>
            <LegendsBanner
              key={String(index)}
              course={data.course}
              bannerType={1}
              enroll={enroll}
              imgWidth={imgWidth}
              leftOverlay={leftOverlay}
            />
          </LegendsBannerWrapper>
        );
      } else if (bannerType === bannerStates.TEST_SERIES) {
        // Test Series  Banner
        banners.push(
          <TestSeriesBanner
            key={String(index)}
            data={data}
            bannerType={1}
            enroll={enroll}
            gridColumn={gridColumn}
            itemWidth={itemWidth}
          />
        );
      } else if (
        bannerType === bannerStates.RENEWAL &&
        !preSubscribed &&
        data.sessionData.show
      ) {
        banners.push(
          <Renew
            key={String(index)}
            data={data.sessionData}
            subscription={plusSubscription}
            gridColumn="span 18"
            lastPrimarySource="Course Page"
          />
        );
      } else if (bannerType === bannerStates.GENERIC) {
        // Summit banner
        const { clickURL, bannerPhoto, bannerAppPhoto } = data?.bannerDetails;
        banners.push(
          <GenericBanner
            key={String(index)}
            block={{
              data: [
                {
                  img: bannerPhoto,
                  appImg: bannerAppPhoto,
                  url: clickURL
                }
              ]
            }}
            gridColumn={gridColumn}
            itemWidth={itemWidth}
            itemHeight={itemHeight}
            priority={index === 0}
          />
        );
      } else if (bannerType === bannerStates.COMBAT && !isMobile)
        banners.push(
          <CombatBanner
            key={String(index)}
            data={data.sessionData}
            gridColumn={gridColumn}
            bannerDetails={data.bannerDetails}
          />
        );
      return null;
    });
    return banners;
  };
  const banners = renderBanners();
  return (
    <>
      {banners && banners.length > 0 ? (
        <Carousel
          label={block.label}
          numberOfItems={1}
          showNavButtons
          showLabel={showLabel}
          showInfo={false}
          showBottomGap={false}
        >
          {banners}
        </Carousel>
      ) : null}
    </>
  );
};

export default CarouselFeedBlock;

CarouselFeedBlock.propTypes = {
  block: PropTypes.objectOf(PropTypes.any).isRequired,
  goalUID: PropTypes.string.isRequired,
  preSubscribed: PropTypes.bool,
  gridColumn: PropTypes.string,
  itemWidth: PropTypes.number,
  itemHeight: PropTypes.number,
  enroll: PropTypes.func,
  imgWidth: PropTypes.string,
  leftOverlay: PropTypes.string,
  showLabel: PropTypes.bool
};

CarouselFeedBlock.defaultProps = {
  preSubscribed: false,
  gridColumn: 'span 18',
  itemWidth: 848,
  itemHeight: 265,
  enroll: null,
  imgWidth: '56.6%',
  leftOverlay: '0px',
  showLabel: false
};
