/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { useState, lazy, Suspense } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import Paper from '@base/Paper/Paper';
import { useSelector } from 'react-redux';
import H4 from '@base/H4/H4';
import P1 from '@base/P1/P1';
import Button from '@base/Button/Button';
import Thumbnail from '@base/Thumbnail/Thumbnail';
import P2 from '@base/P2/P2';
import Link from '@base/Link/Link';
import Share from '@icon/Share';
import AddToList from '@icon/AddToList';
import AddedToList from '@icon/AddedToList';
import pushRoute from '@utils/pushRoute';
import Play from '@icon/Play';
import { useDispatchToStore } from '@hooks/reduxHooks';
import dateHelper, { dateWithTime } from '@utils/dates';
import boxShadowGenerator from '@utils/boxShadowGenerator';
import { Spacings } from '@styles/index';
import { programmeTypes, itemTypes } from '@constants/index';
import Item from '@models/Item';
import Dialog from '@comp/Dialog/Dialog';
import usePurchaseCTAHandler from '@hooks/usePurchaseCTAHandler';
import { getIsSubscribedToGoal } from '@selectors/subscription';

const ShareDialog = lazy(() =>
  import('../../Composite/ShareDialog/ShareDialog')
);

const Wrapper = styled(Paper)`
  display: flex;
  position: relative;
  overflow: hidden;
  width: ${({ $itemWidth }) => $itemWidth || '848px'};
  height: 100%;
  justify-content: space-between;
  align-items: center;
  padding: ${Spacings.SPACING_8B} ${Spacings.SPACING_12B};
`;

const SubTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${Spacings.SPACING_1B};
  margin-bottom: ${Spacings.SPACING_8B};
`;

const ShadowPulse = keyframes`
  0% { box-shadow: ${boxShadowGenerator({
    yOffset: Spacings.SPACING_0B,
    blur: Spacings.SPACING_0B,
    color: 'rgba(235,87,87, 0.4)'
  })}; }
  70% { box-shadow: ${boxShadowGenerator({
    yOffset: Spacings.SPACING_0B,
    blur: Spacings.SPACING_0B,
    spread: '10px',
    color: 'rgba(235,87,87, 0)'
  })}; }
  100% {
    box-shadow: none;
  }
`;

const Live = styled.div`
  margin-left: ${Spacings.SPACING_2B};
  margin-right: ${Spacings.SPACING_3B};
  height: 8px;
  width: 8px;
  background-color: var(--color-i-red);
  border-radius: 50%;
  box-shadow: ${boxShadowGenerator({
    yOffset: Spacings.SPACING_0B,
    blur: Spacings.SPACING_0B,
    color: 'rgba(235,87,87, 0.4)'
  })};
  animation: ${ShadowPulse} 1s infinite;
`;

const SubText = styled(P1)`
  color: var(--color-text-primary);
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  button:first-child {
    margin-right: ${Spacings.SPACING_4B};
  }
  z-index: 1;
`;

const CourseThumbnail = styled(Thumbnail)`
  height: 200px;
  width: 355px;
  border-radius: ${Spacings.SPACING_2B};
  margin-left: ${Spacings.SPACING_18B};
`;

const CourseTitle = styled(P2)`
  width: 320px;
  margin-top: ${Spacings.SPACING_6};
`;

const ItemTitle = styled(H4)`
  width: 320px;
  margin-top: ${({ $isSpecialClass }) =>
    $isSpecialClass ? Spacings.SPACING_4B : Spacings.SPACING_3B};
`;

const TestSeriesBanner = ({ data, enroll, itemWidth }) => {
  const isWindowDefined = typeof window !== 'undefined';
  const dispatch = useDispatchToStore();
  const { course } = data;

  const {
    isLive: isCourseLive,
    isUpcoming: isCourseUpcoming,
    name,
    thumbnail,
    startsAt,
    isSpecial,
    courseType,
    relativeLink,
    goal,
    uid: courseUID
  } = course;
  const { uid: goalUID, slug: goalSlug } = goal || {};
  const isEnrolled =
    useSelector(({ courses }) => courses.data[courseUID]?.isEnrolled) || false;

  const [showShareOptions, setShowShareOptions] = useState(false);
  const isSubscribed = useSelector(getIsSubscribedToGoal(goalUID));
  const nextTrack =
    course.nextTrack?.item && typeof course.nextTrack.item === 'object'
      ? course.nextTrack.item
      : course.nextTrack;
  const previousTrack =
    course.previousTrack?.item && typeof course.previousTrack.item === 'object'
      ? course.previousTrack.item
      : course.previousTrack;
  const CTALabel = usePurchaseCTAHandler(goalUID);

  if (!course || !nextTrack) return null;

  const onShareClick = (e) => {
    e.stopPropagation();
    setShowShareOptions(true);
  };

  const gotoCoursePage = () => {
    const url = course?.relativeLink;
    if (url) window.open(url, '_blank');
  };

  const renderPrimaryButton = () => {
    let buttonText = 'Enroll';
    let icon = <AddToList size="20px" color="var(--color-base-1)" />;
    const track =
      nextTrack?.isLive || !previousTrack?.uid ? nextTrack : previousTrack;
    const isUpcoming = Item.isUpcoming(track);
    let handleClick = (e) => {
      e.stopPropagation();
      if (enroll) dispatch(enroll(course));
    };
    if (!isSubscribed && !isSpecial) {
      buttonText = CTALabel;
      icon = null;
      handleClick = (e) => {
        e.stopPropagation();
        pushRoute(
          '/goal/[goalSlug]/[goalUID]/subscribe',
          `/goal/${goalSlug}/${goalUID}/subscribe`
        );
      };
    } else if (isEnrolled && !isCourseUpcoming) {
      if (track.type === itemTypes.POST) {
        buttonText = 'Watch now';
        icon = <Play size="16px" color="var(--color-base-1)" />;
        handleClick = (e) => {
          e.stopPropagation();
          window.open(`/class/${track.slug}/${track.uid}`, '_blank');
        };
      }
      if (track.type === itemTypes.QUIZ) {
        buttonText = 'Begin test';
        icon = <Play size="16px" color="var(--color-base-1)" />;
        handleClick = (e) => {
          e.stopPropagation();
          window.open(`/quiz/${track.slug}/${track.uid}`, '_blank');
        };
      }
    }
    if (isEnrolled && isCourseUpcoming) {
      buttonText = 'Enrolled';
      icon = <AddedToList size="20px" color="var(--color-text-primary)" />;
      handleClick = () => {};
    }
    return (
      <Button
        grid={4}
        label={buttonText}
        onClick={handleClick}
        variant={isUpcoming && isEnrolled ? 'hollow' : 'filled'}
        color={isUpcoming && isEnrolled ? 'black' : 'green'}
        icon={icon}
      />
    );
  };

  const renderButtons = () => (
    <ButtonsWrapper>
      {renderPrimaryButton()}
      <Button
        grid={3}
        label="Share"
        onClick={onShareClick}
        color="black"
        icon={<Share size="20px" color="var(--color-text-primary)" />}
        variant="hollow"
      />
    </ButtonsWrapper>
  );

  const isMissed = (item) => {
    if (item.type === itemTypes.POST && item.hasWatched) return true;
    if (item.type === itemTypes.QUIZ && item.latestSession) return true;
  };

  const getSecondaryText = () => {
    let secondaryText = `Started at ${dateHelper.formatTime(startsAt)}`;
    if (isCourseUpcoming)
      secondaryText = `Starts on ${dateHelper.getDateMonthString(
        startsAt,
        true
      )}, ${dateHelper.formatTime(startsAt)}`;
    else if (isCourseLive) secondaryText = `Live  •  ${secondaryText}`;
    if (nextTrack?.isLive)
      secondaryText = `Live  •  Started at ${dateHelper.formatTime(
        nextTrack.startedAt || nextTrack.liveAt
      )}`;
    else if (previousTrack?.uid) {
      const sessionMissed = isMissed(previousTrack);
      secondaryText = `${
        previousTrack.type === itemTypes.POST ? 'Lesson' : 'Test'
      } ${previousTrack.contentRank || previousTrack.rank} · `;
      if (sessionMissed) secondaryText += 'Missed · ';
      secondaryText += `${dateHelper.getDateShort(
        previousTrack.liveAt || previousTrack.startedAt
      )}`;
    } else if (nextTrack) {
      secondaryText = `${
        nextTrack.type === itemTypes.POST ? 'Lesson' : 'Test'
      } ${nextTrack.contentRank || nextTrack.rank}`;
      secondaryText += ` · Starts on ${dateWithTime(nextTrack.liveAt)}`;
    }
    return secondaryText;
  };

  const isSpecialClass =
    ((courseType === programmeTypes.COURSE ||
      courseType === programmeTypes.ONLINE_WITH_EXTERNAL_STREAM) &&
      isSpecial) ||
    isCourseUpcoming;

  return (
    <>
      <Wrapper onClick={gotoCoursePage} $itemWidth={itemWidth}>
        <div color="var(--color-text-primary)">
          {!isSpecialClass && (
            <Link
              href={`/${
                courseType === programmeTypes.COURSE ? 'course' : 'test-series'
              }/[slug]/[courseUID]`}
              as={relativeLink}
            >
              <CourseTitle color="var(--color-text-secondary)" lineClamp={1}>
                {name}
              </CourseTitle>
            </Link>
          )}
          <ItemTitle
            color="var(--color-text-primary)"
            $isSpecialClass={isSpecialClass}
            lineClamp={2}
          >
            {isCourseUpcoming
              ? name
              : !nextTrack?.isLive && previousTrack?.name
              ? previousTrack.name
              : nextTrack.name}
          </ItemTitle>
          <SubTextWrapper>
            {nextTrack?.isLive ? <Live /> : null}
            <SubText>{getSecondaryText()}</SubText>
          </SubTextWrapper>
          {renderButtons()}
        </div>
        <CourseThumbnail imgUrl={thumbnail} />
      </Wrapper>
      <Dialog
        onClose={() => setShowShareOptions(false)}
        dialogState={showShareOptions}
      >
        {isWindowDefined && (
          <Suspense fallback={null}>
            <ShareDialog data={course} useDataLink />
          </Suspense>
        )}
      </Dialog>
    </>
  );
};

export default TestSeriesBanner;

TestSeriesBanner.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  course: PropTypes.objectOf(PropTypes.any).isRequired,
  enroll: PropTypes.func,
  itemWidth: PropTypes.number
};

TestSeriesBanner.defaultProps = {
  enroll: null,
  itemWidth: 848
};
