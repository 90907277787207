export const PlusCourseCardClicked = 'PlusCourseCardClicked';
export const FreeCourseCardClicked = 'FreeCourseCardClicked';
export const ClickedOnPlus = 'ClickedOnPlus';
export const PlusGetSubscription = 'Plus: Get Subscription';
export const PlusSelectSubscriptionDuration =
  'Plus: Select Subscription Duration';
export const PlusApplyReferralCode = 'Plus: Apply Referral Code';
export const PlusProceedPayment = 'Plus: Proceed Payment';
export const PlusPaymentSelection = 'Plus: Payment Selection ';
export const PlusPaymentSuccessful = 'Plus: Payment Successful';
export const PlusPaymentFailure = 'Plus: Payment Failure';
export const PlusPaymentPending = 'Plus: Payment Pending';
export const ExtendSubscription = 'Plus: Extend Subscription';
export const SummitRegister = 'Summit: Registration';
export const PlusDashboardTouchpoint = 'Dashboard: Clicked';
export const PlusPayInPartsSuccessful = 'Plus: Pay in Parts Successful';
export const PlusPayInPartsFailure = 'Plus: Pay in Parts Failure';
export const FreeUnlockDrawerDisplay =
  'Free Plan Unlock : Bottom sheet Display';
export const FreePlanUnlockSuccess = 'Free Plan Unlock : Unlocked';
export const FreePlanUnlockFailure = 'Free Plan Unlock : Failure';
