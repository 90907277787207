import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import H3 from '@base/H3/H3';
import Button from '@base/Button/Button';
import P1 from '@base/P1/P1';
import { getImage } from '@utils/imageHelper';
import { getFullDate } from '@utils/dates';
import textHelper, { commas } from '@utils/textHelper';
import pushRoute from '@utils/pushRoute';
import { sendExtendSubscriptionEvent } from '@events/sendAnalytics';
import { Spacings } from '@styles/index';

const {
  SPACING_2B,
  SPACING_7B,
  SPACING_10B,
  SPACING_3B,
  SPACING_4B,
  SPACING_6B
} = Spacings;

const images = {
  25: {
    image: '25percent.svg',
    background: {
      0: 'zigzagRed.svg',
      1: 'zigzagOrange.svg',
      2: 'zigzagBlue.svg',
      3: 'zigzagBlue.svg'
    }
  },
  20: {
    left: {
      0: 'alarmRed.svg',
      1: 'alarmOrange.svg',
      2: 'alarmBlue.svg',
      3: 'alarmBlue.svg',
      4: 'alarmBlue.svg',
      5: 'alarmBlue.svg'
    },
    background: {
      0: 'zigzagRed.svg',
      1: 'zigzagOrange.svg',
      2: 'zigzagBlue.svg',
      3: 'zigzagBlue.svg',
      4: 'zigzagBlue.svg',
      5: 'zigzagBlue.svg'
    }
  },
  15: {
    left: {
      0: 'alarmRed.svg',
      1: '1day.svg',
      2: '2days.svg',
      3: '3days.svg',
      4: '4days.svg',
      5: '5days.svg',
      6: '6days.svg',
      7: '7days.svg'
    },
    background: {
      0: 'zigzagRed.svg',
      1: 'zigzagOrange.svg',
      2: 'zigzagBlue.svg',
      3: 'zigzagBlue.svg',
      4: 'zigzagBlue.svg',
      5: 'zigzagBlue.svg',
      6: 'zigzagBlue.svg',
      7: 'zigzagBlue.svg'
    }
  }
};

const getBackgroundColor = (percentage, daysRemaining) => {
  if (percentage === 25 || percentage === 20 || percentage === 15) {
    if (daysRemaining === 0) return 'var(--color-i-red)';
    if (daysRemaining === 1) return 'var(--color-i-orange-1)';
  }
  return 'var(--color-i-blue)';
};

const Wrapper = styled.div`
  grid-column: ${({ $gridColumn }) => $gridColumn};
  border-radius: ${SPACING_2B};
  height: ${({ $small }) => ($small ? '216px' : '248px')};
  overflow: hidden;
  position: relative;
  display: flex;
  padding: ${SPACING_7B} ${SPACING_10B} ${SPACING_7B} ${SPACING_7B};
  > * {
    position: relative;
  }
`;

const WrapperBg = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: ${SPACING_2B};
  background-color: ${({ $percentage, $daysRemaining }) =>
    getBackgroundColor($percentage, $daysRemaining)};
  opacity: 0.15;
`;

const Image = styled.img`
  position: absolute;
  left: ${({ $small }) => ($small ? '29px' : '35px')};
  height: ${({ $small }) => $small && '159px'};
  width: ${({ $small }) => $small && '159px'};
`;

const Calendar = styled.img`
  position: absolute;
  left: ${({ $small }) => ($small ? '29px' : '8px')};
  top: ${({ $small }) => !$small && 0};
  height: ${({ $small }) => $small && '190px'};
  width: ${({ $small }) => $small && '190px'};
`;

const Content = styled(P1)`
  font-size: ${({ $small }) => $small && '12px'};
  margin-bottom: ${SPACING_6B};
`;

const Highlight = styled.span`
  font-weight: 600;
`;

const Header = styled(H3)`
  font-weight: ${({ $small }) => $small && '600'};
  font-size: ${({ $small }) => $small && '16px'};
  line-height: ${({ $small }) => $small && '150%'};
  margin-top: ${SPACING_3B};
  margin-bottom: ${({ $small }) => ($small ? SPACING_2B : SPACING_4B)};
`;

const Code = styled.div`
  margin-left: ${({ $small }) => ($small ? '211px' : '262px')};
`;

const Img = styled.img`
  position: absolute;
  height: ${({ $small }) => ($small ? '216px' : '248px')};
  top: 0;
`;

const Zig = styled(Img)`
  left: 0;
`;
const Zag = styled(Img)`
  right: 0;
  transform: scaleX(-1);
`;

const renderLeft = (percentage, daysRemaining, small) => {
  if (percentage === 15) {
    return (
      <Calendar
        src={getImage(images[15].left?.[daysRemaining])}
        $small={small}
      />
    );
  }
  if (percentage === 20) {
    return (
      <Image
        src={getImage(images[20].left?.[daysRemaining])}
        $small={small}
        alt="days remaining"
      />
    );
  }
  if (percentage === 25) {
    return (
      <Image
        src={getImage(images[25].image)}
        $small={small}
        alt="days remaining"
      />
    );
  }
};

const Left = styled.div`
  width: ${({ $small }) => ($small ? '283px' : '330px')};
  height: ${({ $small }) => ($small ? '262px' : '330px')};
  position: absolute;
  left: ${({ $small }) => ($small ? '-96px' : '-84px')};
  top: ${({ $small }) => ($small ? '-29px' : '-35px')};
  border-radius: 50%;
`;

const ExtendButton = styled(Button)`
  &:hover {
    box-shadow: none;
  }
  &:active {
    box-shadow: none;
  }
`;

const RenewalCard = ({
  gridColumn,
  data,
  subscription,
  className,
  small,
  lastPrimarySource
}) => {
  const { code, show, maxDiscount, validTill, percentage, daysRemaining } =
    data || {};
  if (!show) return null;
  let days = daysRemaining;
  if (percentage === 20 && daysRemaining > 5) days = 5;
  if (percentage === 15 && daysRemaining > 7) days = 7;

  const handleClick = () => {
    const duration = textHelper.getHigherSubscription(
      Math.floor(subscription?.subscription?.duration / 30)
    );
    pushRoute(
      {
        pathname: '/goal/[goalSlug]/[goalUID]/subscribe',
        query: { referral_code: encodeURIComponent(code || ''), duration }
      },
      `${
        subscription?.subscription?.value.relative_link ||
        subscription?.subscription?.value.relativeLink
      }/subscribe/?referral_code=${encodeURIComponent(
        code || ''
      )}&duration=${duration}`
    );
    sendExtendSubscriptionEvent(
      subscription?.subscription?.value.uid,
      subscription?.subscription?.value.name,
      subscription?.subscription?.type,
      lastPrimarySource
    );
  };
  if (percentage)
    return (
      <Wrapper $gridColumn={gridColumn} className={className} $small={small}>
        <WrapperBg $percentage={percentage} $daysRemaining={days} />
        <Left $percentage={percentage} $daysRemaining={days} $small={small} />
        <Zig
          src={getImage(images[percentage]?.background[days])}
          $small={small}
        />
        <Zag
          src={getImage(images[percentage]?.background[days])}
          $small={small}
        />
        {renderLeft(percentage, days, small)}
        <Code $small={small}>
          <Header $small={small}>
            {percentage === 15 && daysRemaining === 0
              ? 'Your subscription is expiring today'
              : `Extend now and Save ${percentage}%`}
          </Header>
          <Content $small={small}>
            Use code
            <Highlight>{` ${code} `}</Highlight>
            {`and save upto Rs ${commas(
              maxDiscount
            )} when you extend your subscription.`}
            <Highlight>
              {percentage !== 15
                ? ` Valid till ${getFullDate(validTill)}.`
                : null}
            </Highlight>
          </Content>
          <ExtendButton label="Extend Subscription" onClick={handleClick} />
        </Code>
      </Wrapper>
    );
};

export default RenewalCard;

RenewalCard.propTypes = {
  gridColumn: PropTypes.string,
  data: PropTypes.objectOf(PropTypes.any),
  subscription: PropTypes.objectOf(PropTypes.any),
  className: PropTypes.string
};

RenewalCard.defaultProps = {
  gridColumn: 'span 18',
  data: {},
  subscription: {},
  className: ''
};
