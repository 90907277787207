/* eslint-disable max-lines */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-undef */
/* eslint-disable func-names */
import loadScript from '@utils/loadScript';
import { analyticsApi, segment } from '@actions/analytics';
import { captureException } from '@sentry/nextjs';
import { GTMError } from '@utils/customErrors';
import { fetchProxyUserUID } from '@utils/abHelper';
import getPlatform from '@utils/getPlatform';
import { FPU_DISPLAY } from '@constants/segment';
import { noOp } from '@constants/empty';
import {
  GoalId,
  GoalName,
  ComboId,
  ComboName,
  IsCombo,
  SubscriptionDuration,
  LearnerId,
  LearnerUserName,
  Amount,
  IsUserSubscribed,
  IsFirstSubscription,
  NoOfDaysRemainingInSubscription,
  ReferralCode,
  HasReferralCode,
  HasAppliedCredits,
  CreditsApplied,
  PaymentGateway,
  OrderId,
  LastPrimarySource,
  SubscriptionType,
  Platform,
  IsPartPayment,
  CurrentPartPaymentIndex,
  OptedPartPayments,
  ObjectType,
  ObjectId,
  InviteCode
} from './eventProps';
import {
  PlusSelectSubscriptionDuration,
  PlusGetSubscription,
  PlusApplyReferralCode,
  PlusProceedPayment,
  PlusPaymentSelection,
  PlusPaymentSuccessful,
  PlusPaymentFailure,
  PlusPaymentPending,
  ExtendSubscription,
  SummitRegister,
  PlusDashboardTouchpoint,
  PlusPayInPartsSuccessful,
  PlusPayInPartsFailure,
  FreeUnlockDrawerDisplay,
  FreePlanUnlockSuccess,
  FreePlanUnlockFailure
} from './events';

const ANALYTICS_TOKEN = 'kfh2kjhfiwewdsdsd';

const internalUaSetup = (user) => {
  window.ua =
    window.ua ||
    function () {
      (ua.q = ua.q || []).push(arguments);
    };
  ua(
    'create',
    process.env.ANALYTICS_TOKEN || ANALYTICS_TOKEN,
    user && user.uid ? user.uid : undefined
  );
};

const loadFreePlayer = (user) => {
  loadScript(
    'https://player.uacdn.net/lesson-raw/player/player-controller.js?v=3',
    'player-controller'
  ).catch(noOp);
};

const setupUaAnalytics = (user) => {
  try {
    const analyticsURL =
      process.env.ENVIRONMENT === 'gamma'
        ? `${window?.location.origin}/static/ua.js`
        : 'https://static.uacdn.net/ua/ua.js';
    loadScript(analyticsURL, 'uacdn').then(() => {
      internalUaSetup(user);

      setTimeout(() => {
        loadFreePlayer(user);
      }, 2000);
    });
  } catch (e) {
    loadFreePlayer(user);
  }
};

export const updateAnalyticsUser = (user) => {
  window.ua =
    window.ua ||
    function () {
      (ua.q = ua.q || []).push(arguments);
    };
  ua('create', process.env.ANALYTICS_TOKEN || ANALYTICS_TOKEN, user.uid);
};

export const setupAnalytics = (user, eventData) => {
  setupUaAnalytics(user);
};

export const sendEvents = (
  eventName,
  eventData,
  sendMixpanelEvents = true,
  token = ''
) => {
  if (window.isMobile) {
    // eslint-disable-next-line no-param-reassign
    eventData.Platform = getPlatform();
  }
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: eventName,
      ...eventData
    });
  } catch (e) {
    captureException(new GTMError(e));
  }

  analyticsApi(
    {
      name: eventName,
      proxy_uid: fetchProxyUserUID(),
      ...eventData
    },
    token
  );
};

export const sendTTUEvents = (
  eventName,
  eventData = {},
  learnerId,
  learnerUsername,
  goalId,
  goalName
) => {
  segment.track(eventName, {
    ...eventData,
    goal_uid: goalId,
    goal_name: goalName
  });
  sendEvents(eventName, {
    ...eventData,
    [GoalId]: goalId,
    [GoalName]: goalName,
    [LearnerId]: learnerId || 'anonymous',
    [LearnerUserName]: learnerUsername || 'anonymous'
  });
};

export const sendGetSubscriptionEvent = (
  goalId,
  goalName,
  learnerId,
  learnerUsername,
  isUserSubscribed,
  isFirstSubscription,
  noOfDaysRemainingInSubscription
) => {
  const eventData = {
    [GoalId]: goalId,
    [GoalName]: goalName,
    [LearnerId]: learnerId || 'anonymous',
    [LearnerUserName]: learnerUsername || 'anonymous',
    [IsUserSubscribed]: isUserSubscribed,
    [IsFirstSubscription]: isFirstSubscription,
    [NoOfDaysRemainingInSubscription]: noOfDaysRemainingInSubscription,
    [Platform]: 'Desktop'
  };

  sendEvents(PlusGetSubscription, eventData, true, token);
};

export const sendSelectSubscriptionDurationEvent = (
  goalId,
  goalName,
  duration,
  learnerId,
  learnerUsername,
  amount,
  isUserSubscribed,
  isFirstSubscription,
  noOfDaysRemainingInSubscription,
  isCombo,
  comboId,
  comboName
) => {
  const eventData = {
    [GoalId]: goalId,
    [GoalName]: goalName,
    [SubscriptionDuration]: duration,
    [LearnerId]: learnerId || 'anonymous',
    [LearnerUserName]: learnerUsername || 'anonymous',
    [IsCombo]: isCombo,
    [Amount]: amount,
    [IsUserSubscribed]: isUserSubscribed,
    [IsFirstSubscription]: isFirstSubscription,
    [NoOfDaysRemainingInSubscription]: noOfDaysRemainingInSubscription,
    [Platform]: 'Desktop'
  };
  if (isCombo) {
    eventData[ComboId] = comboId;
    eventData[ComboName] = comboName;
  }
  sendEvents(PlusSelectSubscriptionDuration, eventData, true);
};

export const sendFreeUnlockDrawerDisplayEvent = (
  goalId,
  goalName,
  learnerId,
  learnerUsername,
  objectType,
  objectUid
) => {
  const eventData = {
    [GoalId]: goalId,
    [GoalName]: goalName,
    [LearnerId]: learnerId || 'anonymous',
    [LearnerUserName]: learnerUsername || 'anonymous',
    [ObjectType]: objectType,
    [ObjectId]: objectUid,
    [Platform]: 'Desktop'
  };
  segment.track(FPU_DISPLAY, { object_id: objectUid, object_type: objectType });
  sendEvents(FreeUnlockDrawerDisplay, eventData, true);
};

export const sendFreePlanUnlockEvent = (
  goalId,
  goalName,
  learnerId,
  learnerUsername,
  objectType,
  objectUid,
  inviteCode,
  success
) => {
  const eventData = {
    [GoalId]: goalId,
    [GoalName]: goalName,
    [LearnerId]: learnerId || 'anonymous',
    [LearnerUserName]: learnerUsername || 'anonymous',
    [ObjectType]: objectType,
    [ObjectId]: objectUid,
    [InviteCode]: inviteCode,
    [Platform]: 'Desktop'
  };
  const eventType = success ? FreePlanUnlockSuccess : FreePlanUnlockFailure;
  sendEvents(eventType, eventData, true);
};

export const sendApplyReferralCodeEvent = (
  goalId,
  goalName,
  duration,
  learnerId,
  learnerUsername,
  amount,
  referralCode,
  isUserSubscribed,
  isFirstSubscription,
  noOfDaysRemainingInSubscription
) => {
  const eventData = {
    [GoalId]: goalId,
    [GoalName]: goalName,
    [SubscriptionDuration]: duration,
    [LearnerId]: learnerId || 'anonymous',
    [LearnerUserName]: learnerUsername || 'anonymous',
    [Amount]: amount,
    [ReferralCode]: referralCode,
    [IsUserSubscribed]: isUserSubscribed,
    [IsFirstSubscription]: isFirstSubscription,
    [NoOfDaysRemainingInSubscription]: noOfDaysRemainingInSubscription,
    [Platform]: 'Desktop'
  };
  sendEvents(PlusApplyReferralCode, eventData, true);
};

export const sendProceedPaymentEvent = (
  goalId,
  goalName,
  duration,
  learnerId,
  learnerUsername,
  amount,
  hasReferralCode,
  referralCode,
  isUserSubscribed,
  isFirstSubscription,
  noOfDaysRemainingInSubscription
) => {
  const eventData = {
    [GoalId]: goalId,
    [GoalName]: goalName,
    [SubscriptionDuration]: duration,
    [LearnerId]: learnerId || 'anonymous',
    [LearnerUserName]: learnerUsername || 'anonymous',
    [Amount]: amount,
    [HasReferralCode]: hasReferralCode,
    [ReferralCode]: referralCode,
    [IsUserSubscribed]: isUserSubscribed,
    [IsFirstSubscription]: isFirstSubscription,
    [NoOfDaysRemainingInSubscription]: noOfDaysRemainingInSubscription,
    [Platform]: 'Desktop'
  };
  sendEvents(PlusProceedPayment, eventData, true);
};

export const sendPaymentSelectionEvent = (
  goalId,
  goalName,
  duration,
  learnerId,
  learnerUsername,
  amount,
  hasReferralCode,
  referralCode,
  hasAppliedCredits,
  creditsApplied,
  paymentGateway,
  isUserSubscribed,
  isFirstSubscription,
  noOfDaysRemainingInSubscription
) => {
  const eventData = {
    [GoalId]: goalId,
    [GoalName]: goalName,
    [SubscriptionDuration]: duration,
    [LearnerId]: learnerId || 'anonymous',
    [LearnerUserName]: learnerUsername || 'anonymous',
    [Amount]: amount,
    [HasReferralCode]: hasReferralCode,
    [ReferralCode]: referralCode,
    [HasAppliedCredits]: hasAppliedCredits,
    [CreditsApplied]: creditsApplied,
    [PaymentGateway]: paymentGateway,
    [IsUserSubscribed]: isUserSubscribed,
    [IsFirstSubscription]: isFirstSubscription,
    [NoOfDaysRemainingInSubscription]: noOfDaysRemainingInSubscription,
    [Platform]: 'Desktop'
  };
  sendEvents(PlusPaymentSelection, eventData, true);
};

export const sendPaymentSuccessfulEvent = (
  goalId,
  goalName,
  duration,
  learnerId,
  learnerUsername,
  amount,
  orderId,
  isUserSubscribed,
  subscriptionType,
  partPaymentInfo,
  isCombo,
  comboId,
  comboName
) => {
  const eventData = {
    [GoalId]: goalId,
    [GoalName]: goalName,
    [SubscriptionDuration]: duration,
    [LearnerId]: learnerId || 'anonymous',
    [LearnerUserName]: learnerUsername || 'anonymous',
    [Amount]: amount,
    [OrderId]: orderId,
    [IsUserSubscribed]: isUserSubscribed,
    [SubscriptionType]: subscriptionType,
    [Platform]: 'Desktop',
    [IsPartPayment]: partPaymentInfo?.isPartPartment || false,
    [CurrentPartPaymentIndex]: partPaymentInfo?.currentPartPaymentIndex || null,
    [OptedPartPayments]: partPaymentInfo?.optedPartPayments || null,
    [IsCombo]: isCombo
  };
  if (isCombo) {
    eventData[ComboId] = comboId;
    eventData[ComboName] = comboName;
  }
  const eventType =
    partPaymentInfo?.isPartPartment && !partPaymentInfo?.isLastPartPayment
      ? PlusPayInPartsSuccessful
      : PlusPaymentSuccessful;
  sendEvents(eventType, eventData, true);
};

export const sendPaymentFailureEvent = (
  goalId,
  goalName,
  duration,
  learnerId,
  learnerUsername,
  amount,
  orderId,
  isUserSubscribed,
  subscriptionType,
  partPaymentInfo,
  isCombo,
  comboId,
  comboName
) => {
  const eventData = {
    [GoalId]: goalId,
    [GoalName]: goalName,
    [SubscriptionDuration]: duration,
    [LearnerId]: learnerId || 'anonymous',
    [LearnerUserName]: learnerUsername || 'anonymous',
    [Amount]: amount,
    [OrderId]: orderId,
    [IsUserSubscribed]: isUserSubscribed,
    [SubscriptionType]: subscriptionType,
    [Platform]: 'Desktop',
    [IsPartPayment]: partPaymentInfo?.isPartPartment || false,
    [IsCombo]: isCombo
  };
  if (isCombo) {
    eventData[ComboId] = comboId;
    eventData[ComboName] = comboName;
  }
  const eventType =
    partPaymentInfo?.isPartPartment && !partPaymentInfo?.isLastPartPayment
      ? PlusPayInPartsFailure
      : PlusPaymentFailure;
  sendEvents(eventType, eventData, true);
};

export const sendPaymentPendingEvent = (
  goalId,
  goalName,
  duration,
  learnerId,
  learnerUsername,
  amount,
  orderId,
  isUserSubscribed,
  subscriptionType,
  isCombo,
  comboId,
  comboName
) => {
  const eventData = {
    [GoalId]: goalId,
    [GoalName]: goalName,
    [SubscriptionDuration]: duration,
    [LearnerId]: learnerId || 'anonymous',
    [LearnerUserName]: learnerUsername || 'anonymous',
    [Amount]: amount,
    [OrderId]: orderId,
    [IsUserSubscribed]: isUserSubscribed,
    [SubscriptionType]: subscriptionType,
    [Platform]: 'Desktop',
    [IsCombo]: isCombo
  };
  if (isCombo) {
    eventData[ComboId] = comboId;
    eventData[ComboName] = comboName;
  }
  sendEvents(PlusPaymentPending, eventData, true);
};

export const sendExtendSubscriptionEvent = (
  goalId,
  goalName,
  subscriptionType,
  lastPrimarySource
) => {
  const eventData = {
    [GoalId]: goalId,
    [GoalName]: goalName,
    [SubscriptionType]: subscriptionType,
    [LastPrimarySource]: lastPrimarySource
  };
  sendEvents(ExtendSubscription, eventData);
};

export const sendSummitRegisterEvent = (
  learnerId,
  learnerUsername,
  summitUid
) => {
  const eventData = {
    [LearnerId]: learnerId || 'anonymous',
    [LearnerUserName]: learnerUsername || 'anonymous',
    'Summit Uid': summitUid
  };
  sendEvents(SummitRegister, eventData, true);
};

export const dashboardTouchpointEvent = (
  touchpoint,
  learnerId,
  learnerUsername
) => {
  const eventData = {
    [LearnerId]: learnerId || 'anonymous',
    [LearnerUserName]: learnerUsername || 'anonymous',
    Touchpoint: touchpoint
  };
  sendEvents(PlusDashboardTouchpoint, eventData);
};
