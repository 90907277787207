import useFetch from '@hooks/useFetch';
import { getIsLoggedIn } from '@api/selectors/auth';
import useMyInfo from '@api/hooks/auth/useMyInfo';
import { HOST_TYPE } from '@utils/api-helper/host';

const usePendingPaymentStatus = (goalUID) => {
  const isLoggedIn = useMyInfo(getIsLoggedIn);
  const { data: pendingPaymentStatus, ...rest } = useFetch(
    goalUID && isLoggedIn
      ? `v1/subscriptions/pending_method/status/?goal_uid=${goalUID}`
      : null,
    { hostType: HOST_TYPE.BACKEND_API_HOST }
  );

  return { pendingPaymentStatus, ...rest };
};

export default usePendingPaymentStatus;
