import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import H3 from '@base/H3/H3';
import H5 from '@base/H5/H5';
import P1 from '@base/P1/P1';
import Image from '@base/Image';
import Paper from '@base/Paper/Paper';
import Button from '@base/Button/Button';
import useWindowSize from '@hooks/useWindowSize';
import pushRoute from '@utils/pushRoute';
import { fullDateWithTime } from '@utils/dates';
import { emptyObject } from '@constants/empty';
import { breakpoints, Spacings } from '@styles/index';
import useGoalInfo from '@api/hooks/goal/useGoalInfo';

const CombatLogo = 'combat/logo.svg';
const ListingBanner = 'combat/listing-banner.svg';

const Wrapper = styled(Paper)`
  width: 100%;
  grid-column: span 18;
  padding: ${Spacings.SPACING_10B};
  margin-bottom: ${Spacings.SPACING_6B};
  position: relative;
`;

const Background = styled.div`
  position: absolute;
  right: -${Spacings.SPACING_2B};
  bottom: -${Spacings.SPACING_1B};
`;

const Header = styled(H3)`
  margin-top: ${Spacings.SPACING_5B};
  margin-bottom: ${Spacings.SPACING_3B};
  width: calc(100% - 400px - 10px); /* 400px image width plus 10px offset */

  @media only screen and (max-width: ${breakpoints.mobile}) {
    width: 100%;
  }
`;

const Description = styled(P1)`
  margin-bottom: ${Spacings.SPACING_4B};
  width: calc(100% - 400px - 10px); /* 400px image width plus 10px offset */

  @media only screen and (max-width: ${breakpoints.mobile}) {
    width: 100%;
  }
`;

const Date = styled.span`
  color: var(--color-i-blue);
`;

const NextCombat = styled(H5)`
  color: var(--color-text-primary);
  margin-bottom: ${Spacings.SPACING_4B};
`;

const CombatBanner = ({ data }) => {
  const router = useRouter();
  const {
    query: { goalSlug, goalUID }
  } = router;

  const { goalInfo: goalDetails } = useGoalInfo(goalUID);

  const { isMobile } = useWindowSize();

  const handleOnLearnMore = () => {
    pushRoute({
      pathname: '/combat/[goalSlug]/[goalUID]',
      query: {
        goalSlug,
        goalUID
      }
    });
  };

  const goalName = goalDetails?.name || '';
  const { date: nextCombatDate } = data || emptyObject;

  return (
    <Wrapper>
      <Image src={CombatLogo} height={40} width={134} alt="combat-logo" />
      <Header>{`The most competitive, gamified ${goalName} battle till date`}</Header>
      <Description>
        Compete with thousands of learners in the weekly scholarship test
      </Description>
      {nextCombatDate && (
        <NextCombat>
          <span>Next Combat on </span>
          <Date>{fullDateWithTime(nextCombatDate, ' at ')}</Date>
        </NextCombat>
      )}
      <Button
        grid={3}
        label="Learn more"
        variant="hollow"
        color="black"
        onClick={handleOnLearnMore}
      />
      {!isMobile && (
        <Background>
          <Image
            src={ListingBanner}
            height={300}
            width={400}
            alt="combat-background"
          />
        </Background>
      )}
    </Wrapper>
  );
};

export default CombatBanner;

CombatBanner.propTypes = {
  data: PropTypes.objectOf(PropTypes.any)
};

CombatBanner.defaultProps = {
  data: {}
};
